<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 w-4/5"
            >
              Transfert de fonds
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 round">

            <div class="text-c12 mt-4 text-left bloc p-6">
              <div class="flex items-center">
                <div class="w-1/2 mr-4">
                  <div class="text-yell">Montant</div>
                  <div class="text-c22 font-c6">
                    {{ donnes.amount.toLocaleString() }}  <span class="text-c4">FCFA</span>
                  </div>
                </div>

                <div class="w-1/2">
                  <div class="text-b text-c11">
                    <span v-if="donnes.method === 'CASH'">Espèces</span>
                    <span v-if="donnes.method === 'MOBILE_MONEY'">Mobile Money</span>
                  </div>
                  <div
                      class="text-c13 mt-2 flex cursor-pointer items-center"
                      @click="$emit('oga', true)"
                      v-if="donnes.method !== 'MOBILE_MONEY'"
                  >
                    <div>
                      <span v-if="donnes.method === 'CASH'">Billetage</span>
                      <span v-if="donnes.method === 'BANK_CHECK'">Chèques</span>
                    </div>
                    <icon
                        :data="icons.arrow"
                        height="20"
                        width="20"
                        class="ml-3"
                        color="black"
                        original
                    />
                  </div>
                </div>
              </div>

              <div class="text-c12 mt-4 text-left flex items-center pb-4" v-if="donnes.method === 'CASH'">
                <div class="w-1/4">
                  Espèce
                </div>

                <div class="diviser w-full h-1 ml-2 mr-2"/>

                <div class="text-right w-1/3 font-c6">
                  {{ donnes.amount.toLocaleString() }} fcfa
                </div>
              </div>

              <div class="text-c12 mt-4 text-left flex items-center" v-if="donnes.method === 'BANK_CHECK'">
                <div class="w-1/4">
                  Chèques
                </div>

                <div class="diviser w-full h-1 ml-2 mr-2"/>

                <div class="text-right w-1/3 font-c6">
                  {{ donnes.amount.toLocaleString() }} fcfa
                </div>
              </div>
            </div>

            <div class="flex text-left mt-6 items-center">
              <div class="bloc1 p-4 w-1/2">
                <div class="text-90 text-c11">Agence:</div>
                <div class="text-c13 font-c6 mt-2">
                  <span v-if="donnes.wallet">{{ donnes.wallet.agency.label }}</span>
                </div>
              </div>

              <icon
                  :data="icons.transfere"
                  height="30"
                  width="30"
                  class="cursor-pointer ml-6 mr-6"
                  original
              />

              <div class="bloc1 p-4 w-1/2">
                <div class="text-90 text-c11">Caisse bénéficiaire</div>
                <div class="text-c13 font-c6 mt-2">
                  <span v-if="donnes.transfer.receiver.person.user.type === 'ACCOUNTING'">Caisse recette comptabilité</span>
                  <span v-if="donnes.transfer.receiver.person.user.type !== 'ACCOUNTING'">{{ donnes.transfer.receiver.person.fullname }}</span>
                </div>
              </div>
            </div>

            <div class="mt-6 text-left">
              <div class="text-c13"  v-if="donnes.method === 'MOBILE_MONEY'">
                <span class="text-90">Référence : </span>
                <span
                    class="ml-2"
                    v-if="donnes.transfer.meta && donnes.transfer.meta.reference"
                >
                  {{ donnes.transfer.meta.reference }}
                </span>
              </div>

              <div class="text-c13 mt-4">
                <span class="text-90">Destinataire :</span> {{ donnes.transfer.receiver.person.fullname }}
              </div>

              <div class="text-c13 mt-4">
                <span class="text-90">Signataire :</span> {{ donnes.transfer.person.fullname }}
              </div>

              <div class="text-c13 mt-4">
                <div class="text-90">Note :</div>
                <div class="text-c11 mt-1 text-c3">
                  {{ donnes.transfer.note }}
                </div>
              </div>

            </div>

<!--            <div class="text-60 text-c14 text-left bg-fa flex items-center bloc2 p-3 mt-6">-->
<!--              <icon-->
<!--                  :data="icons.document"-->
<!--                  height="20"-->
<!--                  width="20"-->
<!--                  class="cursor-pointer mr-4"-->
<!--                  original-->
<!--              />-->
<!--              <div>-->
<!--                Borderaux de transfert-->
<!--              </div>-->
<!--            </div>-->

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>

            <div class="flex mt-6">
              <buton
                  label="Fermer"
                  radius="5px"
                  background="#ECECEC"
                  color="black"
                  height="50px"
                  :charge="charge"
                  class="w-1/2"
                  @oga="fermer"
              />

              <buton
                  label="Imprimer"
                  radius="5px"
                  height="50px"
                  background="#5138EE"
                  :charge="charge"
                  class="w-1/2 ml-6"
                  @info="openPdf"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import arrow from '../../assets/icons/arrow.svg'
import transfere from '../../assets/icons/transfere.svg'
import document from '../../assets/icons/document.svg'
// import http from "../../plugins/https"
// import apiroutes from "../../router/api-routes"

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    estimation: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer,
        arrow,
        transfere,
        document
      },
      error: null,
      charge: false,
    }
  },

  created() {
    this.donnes = this.donne
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    openPdf () {
      window.open(this.donnes.transfer.pdf, '_blank');
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }
    .diviser {
      border-bottom: 1px dashed #606060;
    }
    .moyens {
      background-color: #F5F5F5;
    }

    .round {
      border-radius: 0px 0px 12px 12px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }

    .bloc1{
      border: 0.832299px solid #ECECEC;
      box-sizing: border-box;
      border-radius: 6.65839px;
    }

    .bloc2 {
      border: 0.832299px dashed #CCCCCC;
      box-sizing: border-box;
      border-radius: 4.16149px;
    }

    .bloc{
      min-height: 40px;
      background: #FFF6EF;
      border-radius: 5px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
